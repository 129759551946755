import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

const Career = ({ data }) => {
	const career = data.strapiCareer;

	return (
		<Layout>
			<GatsbySeo
				title="Work With Us"
				description="Find your dream career at Art of Bicycle Trips. Take a look at our available roles and start your journey with us today."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/about-us/work-with-us/",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/about-us/work-with-us/",
				// 	},
				// ]}
			/>
			<div className="my-20">
				<h1 className="font-bold">Work With Us</h1>
				<h2 className="font-light text-primary max-w-3xl mb-14">
					We are committed to our guests having a brilliant time, and that
					responsibility rests on the shoulders of all of us.
				</h2>
				{career.post &&
					career.post.map((posts) => {
						return (
							<div className="mb-14 " key={posts.id}>
								<ReactMarkdown
									className="prose prose-sm sm:prose mb-14"
									children={posts.desc}
								/>
								<hr></hr>
							</div>
						);
					})}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query CareerQuery {
		strapiCareer {
			post {
				desc
				id
			}
		}
	}
`;

export default Career;
